// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-bellcurve-video-game-js": () => import("./../../../src/pages/code/bellcurve_video_game.js" /* webpackChunkName: "component---src-pages-code-bellcurve-video-game-js" */),
  "component---src-pages-code-index-js": () => import("./../../../src/pages/code/index.js" /* webpackChunkName: "component---src-pages-code-index-js" */),
  "component---src-pages-code-project-fun-learning-company-website-js": () => import("./../../../src/pages/code/project_fun_learning_company_website.js" /* webpackChunkName: "component---src-pages-code-project-fun-learning-company-website-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-design-index-js": () => import("./../../../src/pages/design/index.js" /* webpackChunkName: "component---src-pages-design-index-js" */),
  "component---src-pages-history-index-js": () => import("./../../../src/pages/history/index.js" /* webpackChunkName: "component---src-pages-history-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-skills-index-js": () => import("./../../../src/pages/skills/index.js" /* webpackChunkName: "component---src-pages-skills-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

